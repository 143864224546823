import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Header.css";

const Header = () => {
  const [activeMenu, setActiveMenu] = useState("");
  const handleMenuClick = (menu) => {
    console.log(menu)
    setActiveMenu(menu);
  };
  return (
    <div className="header">
      <div className="logo">cey.dog</div>
      <nav className="nav">
        <Link
          className={activeMenu === "about" ? "active" : ""}
          onClick={() => handleMenuClick("about")}
          to="/about"
        >
          ABOUT ME
        </Link>
        <Link
          className={activeMenu === "project" ? "active" : ""}
          onClick={() => handleMenuClick("project")}
          to="/"
        >
          PROJECT PORTFOLIO
        </Link>
        <Link
          className={activeMenu === "stack" ? "active" : ""}
          onClick={() => handleMenuClick("stack")}
          to="/stack"
        >
          TECH STACK
        </Link>
      </nav>
    </div>
  );
};

export default Header;
