import React from "react";
import ProjectCard from "./projects/ProjectCard";
import "../styles/Projects.css";
import {
  ceydogComStack,
  exdevStack,
  astrotaleStack,
  songStack,
  textbeeStack,
  deyimlerStack,
  sentimentStack,
  airQualityStack,
  gameStack,
  fullCwStack,
  eCommerceStack,
} from "../components/projects/Images";
import ProfileCard from "./projects/ProfileCard";

function Projects() {
  const cardsData = [
    {
      title: "✍🏻 ceydog.com",
      description: "This website, developed by me!",
      stack: ceydogComStack,
    },
    {
      title: "🧑🏽‍💻 Expert Developer Finder - Senior Project",
      date: 2023,
      description:
        "Get connected with the best experts in your codebase in seconds.",
      stack: exdevStack,
      link: "https://egeergul.github.io/SeniorProject/",
    },
    {
      title: "astrotale",
      description:
        "Generate your daily AI-powered horoscope readings and images.",
      stack: astrotaleStack,
      link: "https://play.google.com/store/apps/details?id=com.celestialStudios.astrotale&hl=en_US&gl=US",
    },
    {
      title: "🎵 Song Lyrics Translator",
      date: 2023,
      description: "Translate 2000+ song lyrics in 100+ languages.",
      stack: songStack,
    },
    {
      title: "💬 TextBee: Web Texting Platform",
      description: "Chat with friends and groups in realtime.",
      stack: textbeeStack,
      link: "https://github.com/tuzgen/TextBee",
    },
    {
      title: "🌪️ Air Quality REST API",
      description:
        "Read air pollution information by city name and date range.",
      stack: airQualityStack,
      link: "https://github.com/ceydas/air_quality_rest",
    },
    {
      title: "🎮 Game Backend - Leaderboard and Matchmaking",
      description:
        "Enter tournaments, match with others, view global leaderboard.",
      stack: gameStack,
      link: "https://github.com/ceydas/game-backend",
    },
    {
      title: "⚙️ Full Stack Admin Configuration Panel",
      description:
        "Responsive admin configuration management panel and REST API for configurations.",
      stack: fullCwStack,
      link: "https://github.com/ceydas/fullstack-admin-panel",
    },
    {
      title: "🛍️ E-commerce Listings REST API",
      description:
        "Perform basic CRUD and filter out bad words in listings using optimized (linear time) regex.",
      stack: eCommerceStack,
      link: "https://github.com/ceydas/ecommerce-listings",
    },

    {
      title: "📊 Sentiment Analysis",
      description:
        "Sentiment Analysis for an imbalanced dataset: amazon product reviews.",
      stack: sentimentStack,
    },
    {
      title: "📕 Deyimler & Atasözleri Thesaurus",
      description:
        "Find Turkish idioms & proverbs based on a particular context.",
      stack: deyimlerStack,
      link: "https://github.com/ceydas/deyim-atasozleri-thesaurus",
    },
  ];
  return (
    <div className="projects-page-container">
      <div className="profile-card">
        <ProfileCard></ProfileCard>
      </div>
      <div className="projects-container">
        {cardsData.map((card, index) => (
          <ProjectCard
            title={card.title}
            description={card.description}
            stackIcons={card.stack}
            link={card.link}
          />
        ))}
      </div>
    </div>
  );
}

export default Projects;
