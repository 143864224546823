import React from "react";
import "../styles/NameBanner.css";
import "../styles/TechStack.css";
import vueLogo from "../assets/stack/vue-logo.png";

function TechStack() {
  return (
    <div>
      <div className="page-container">
        <div>
          <div className="title-stack-container">
            <p className="pl">Programming Languages</p>
            <div className="stack-grid">
              <p align="left">
                <a
                  href="https://www.cprogramming.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src="https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg"
                    alt="c"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
                <a
                  href="https://www.w3schools.com/cpp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src="https://raw.githubusercontent.com/devicons/devicon/master/icons/cplusplus/cplusplus-original.svg"
                    alt="cplusplus"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
                <a
                  href="https://www.java.com/en/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src="https://upload.wikimedia.org/wikipedia/tr/2/2e/Java_Logo.svg"
                    alt="Java"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
                <a
                  href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
                    alt="javascript"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
                <a
                  href="https://www.python.org"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src="https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg"
                    alt="python"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
                <a
                  href="https://kotlinlang.org"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src="https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg"
                    alt="kotlin"
                    width="60"
                    height="60"
                  />{" "}
                </a>
              </p>
            </div>
          </div>
          <div>
            <div className="title-stack-container">
              <p className="pl">Back End Development</p>
              <div className="stack-grid">
                <a href="https://spring.io/" target="_blank" rel="noreferrer">
                  {" "}
                  <img
                    src="https://www.vectorlogo.zone/logos/springio/springio-icon.svg"
                    alt="spring"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
                <a href="https://nodejs.org" target="_blank" rel="noreferrer">
                  {" "}
                  <img
                    src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg"
                    alt="nodejs"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
                <a href="https://heroku.com" target="_blank" rel="noreferrer">
                  {" "}
                  <img
                    src="https://www.vectorlogo.zone/logos/heroku/heroku-icon.svg"
                    alt="heroku"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
                <a href="https://postman.com" target="_blank" rel="noreferrer">
                  {" "}
                  <img
                    src="https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg"
                    alt="postman"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
                <a
                  href="https://expressjs.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src="https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg"
                    alt="express"
                    width="60"
                    height="60"
                  />{" "}
                </a>{" "}
              </div>
            </div>
            <div>
              <div className="title-stack-container">
                <p className="pl">Cloud Technologies</p>
                <div className="stack-grid">
                  <a
                    href="https://aws.amazon.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://raw.githubusercontent.com/devicons/devicon/master/icons/amazonwebservices/amazonwebservices-original-wordmark.svg"
                      alt="aws"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://cloud.google.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://www.vectorlogo.zone/logos/google_cloud/google_cloud-icon.svg"
                      alt="gcp"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>

            <div>
              <div className="title-stack-container">
                <p className="pl">Databases / Storage</p>
                <div className="stack-grid">
                  <a
                    href="https://www.mysql.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg"
                      alt="mysql"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://www.postgresql.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg"
                      alt="postgresql"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a href="https://neo4j.com" target="_blank" rel="noreferrer">
                    {" "}
                    <img
                      src="https://dist.neo4j.com/wp-content/uploads/20170726233003/hello-world-neo4j-inc-company-name-change.png"
                      alt="postgresql"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://kafka.apache.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://www.vectorlogo.zone/logos/apache_kafka/apache_kafka-icon.svg"
                      alt="kafka"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a href="https://redis.io" target="_blank" rel="noreferrer">
                    {" "}
                    <img
                      src="https://raw.githubusercontent.com/devicons/devicon/master/icons/redis/redis-original-wordmark.svg"
                      alt="redis"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://firebase.google.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg"
                      alt="firebase"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://www.mongodb.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg"
                      alt="mongodb"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://www.elastic.co"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://www.vectorlogo.zone/logos/elastic/elastic-icon.svg"
                      alt="elasticsearch"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>

            <div>
              <div className="title-stack-container">
                <p className="pl">
                  Software Development / Version Control / Containerization
                </p>
                <div className="stack-grid">
                  <a
                    href="https://www.docker.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg"
                      alt="docker"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://git-scm.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg"
                      alt="git"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://www.linux.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg"
                      alt="linux"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>

            <div>
              <div className="title-stack-container">
                <p className="pl">Front End Frameworks</p>
                <div className="stack-grid">
                  <a
                    href="https://reactjs.org/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg"
                      alt="react"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a href="https://vuejs.org" target="_blank" rel="noreferrer">
                    {" "}
                    <img src={vueLogo} alt="vue" width="60" height="60" />{" "}
                  </a>{" "}
                </div>
              </div>

              <div>
                <div className="title-stack-container">
                  <p className="pl">ML / Data</p>
                  <div className="stack-grid">
                    <a
                      href="https://pandas.pydata.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <img
                        src="https://raw.githubusercontent.com/devicons/devicon/2ae2a900d2f041da66e950e4d48052658d850630/icons/pandas/pandas-original.svg"
                        alt="pandas"
                        width="60"
                        height="60"
                      />{" "}
                    </a>{" "}
                    <a
                      href="https://opencv.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <img
                        src="https://www.vectorlogo.zone/logos/opencv/opencv-icon.svg"
                        alt="opencv"
                        width="60"
                        height="60"
                      />{" "}
                    </a>{" "}
                    <a
                      href="https://pytorch.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <img
                        src="https://www.vectorlogo.zone/logos/pytorch/pytorch-icon.svg"
                        alt="pytorch"
                        width="60"
                        height="60"
                      />{" "}
                    </a>{" "}
                    <a
                      href="https://www.tensorflow.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <img
                        src="https://www.vectorlogo.zone/logos/tensorflow/tensorflow-icon.svg"
                        alt="tensorflow"
                        width="60"
                        height="60"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="title-stack-container">
                <p className="pl">Mobile Application Development</p>
                <div className="stack-grid">
                  <a
                    href="https://flutter.dev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://www.vectorlogo.zone/logos/flutterio/flutterio-icon.svg"
                      alt="flutter"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://developer.android.com/studio"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Android_Studio_icon_%282023%29.svg/1024px-Android_Studio_icon_%282023%29.svg.png
"
                      alt="flutter"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>

            <div>
              <div className="title-stack-container">
                <p className="pl">Automation</p>
                <div className="stack-grid">
                  <a
                    href="https://www.selenium.dev"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/d/d5/Selenium_Logo.png"
                      alt="selenium"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>

            <div>
              <div className="title-stack-container">
                <p className="pl">Other</p>
                <div className="stack-grid">
                  <a
                    href="https://www.adobe.com/products/xd.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://cdn.worldvectorlogo.com/logos/adobe-xd.svg"
                      alt="xd"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://www.photoshop.com/en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/2101px-Adobe_Photoshop_CC_icon.svg.png"
                      alt="photoshop"
                      height="60px"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://www.adobe.com/in/products/illustrator.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://www.vectorlogo.zone/logos/adobe_illustrator/adobe_illustrator-icon.svg"
                      alt="illustrator"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                  <a
                    href="https://www.figma.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <img
                      src="https://www.vectorlogo.zone/logos/figma/figma-icon.svg"
                      alt="figma"
                      width="60"
                      height="60"
                    />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechStack;
