// images.js
import appEngineLogo from "../../assets/stack/appengine-logo.png";
import cloudFrontLogo from "../../assets/stack/cloudfront-logo.png";
import elasticLogo from "../../assets/stack/elastic-logo.png";
import expressLogo from "../../assets/stack/express-logo.png";
import firebaseLogo from "../../assets/stack/firebase_logo.png";
import flutterLogo from "../../assets/stack/flutter_logo.png";
import gcFunctionsLogo from "../../assets/stack/gc_functions_logo.png";
import gcStoreLogo from "../../assets/stack/gc-store-logo.png";
import gcpLogo from "../../assets/stack/gcp-logo.png";
import gensimLogo from "../../assets/stack/gensim-logo.png";
import gptLogo from "../../assets/stack/gpt_logo.png";
import mongoLogo from "../../assets/stack/mongo-logo.png";
import neo4jLogo from "../../assets/stack/neo4j-logo.png";
import nodejsLogo from "../../assets/stack/nodejs-logo.png";
import numpyLogo from "../../assets/stack/numpy-logo.png";
import postgresImage from "../../assets/stack/postgres.png";
import pyLogo from "../../assets/stack/py-logo.png";
import reactjsLogo from "../../assets/stack/reactjs-logo.png";
import s3Logo from "../../assets/stack/s3-logo.png";
import translateLogo from "../../assets/stack/translate-logo.png";
import vscodeLogo from "../../assets/stack/vscode-logo.png";
import githubLogo from "../../assets/stack/github-logo.png"
import springBootLogo from "../../assets/stack/spring.png"
import kafkaLogo from "../../assets/stack/kafka.png";
import redisLogo from "../../assets/stack/redis.png";
import mySqlLogo from "../../assets/stack/mysql.png";
import vueLogo from "../../assets/stack/vue-logo.png"
import dockerLogo from "../../assets/stack/docker.png";
import route53Logo from "../../assets/route53.png";

export const stackIcons = [
  appEngineLogo,
  cloudFrontLogo,
  springBootLogo,
  elasticLogo,
  expressLogo,
  firebaseLogo,
  flutterLogo,
  gcFunctionsLogo,
  gcStoreLogo,
  gcpLogo,
  gensimLogo,
  gptLogo,
  mongoLogo,
  neo4jLogo,
  nodejsLogo,
  numpyLogo,
  postgresImage,
  pyLogo,
  reactjsLogo,
  s3Logo,
  translateLogo,
  vscodeLogo,
  githubLogo,
  redisLogo,
  kafkaLogo,
  vueLogo,
  dockerLogo
];

export const ceydogComStack = [reactjsLogo, s3Logo, cloudFrontLogo, route53Logo];
export const exdevStack = [vscodeLogo, neo4jLogo,githubLogo, mongoLogo, reactjsLogo, nodejsLogo];
export const astrotaleStack = [flutterLogo, gptLogo, gcFunctionsLogo, firebaseLogo];
export const songStack = [nodejsLogo,gcpLogo, gcFunctionsLogo,gcStoreLogo,translateLogo, appEngineLogo, reactjsLogo];
export const textbeeStack = [nodejsLogo, expressLogo, reactjsLogo, elasticLogo, postgresImage];
export const deyimlerStack = [s3Logo, pyLogo, reactjsLogo];
export const sentimentStack = [pyLogo, gensimLogo, numpyLogo];
export const airQualityStack = [springBootLogo, postgresImage]
export const gameStack = [springBootLogo, redisLogo, kafkaLogo, mySqlLogo, dockerLogo];
export const fullCwStack = [vueLogo,nodejsLogo,gcStoreLogo, appEngineLogo, firebaseLogo];
export const eCommerceStack = [springBootLogo]
