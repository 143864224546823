import React from "react";
import NameBanner from "../NameBanner";
import profile from "../../assets/profile.png";
import githubicon from "../../assets/github_logo.svg";
import linkedicon from "../../assets/linkedin_logo.svg";
import mail from "../../assets/mail.png";

function ProfileCard() {
  return (
    <div>
      <div class="profile-card">
        <a href="/about">
          <img src={profile} alt="profile" class="profile-picture"></img>
        </a>
        <div className="name-banner-profile">
          <NameBanner></NameBanner>
        </div>
        <div>
          <p class="profile-bio">
            I am Ceyda, a software engineer, and self-taught visual designer.
          </p>
          <br />
          <p class="profile-bio">
            Some of my proudest work are private repositories. Contact me for
            detailed demos 🧐
          </p>
          <br />
          <p style={{marginBottom:'0.3vw'}}className="profile-bio">
            🎓 Bilkent University - Computer Science (2024)
          </p>
          <p className="profile-bio">🎓 Kadıköy Anadolu Lisesi (2019)</p>
        </div>
        <br />
        <div className="profile-social-icons">
          <a rel="noreferrer" target="_blank" href="https://github.com/ceydas">
            <img alt="github" src={githubicon}></img>
          </a>
          {"  "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/in/ceydasahin/"
          >
            <img alt="linkedin" src={linkedicon}></img>
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="mailto:ceydasahin889@gmail.com"
          >
            <img alt="mail"
              style={{ marginLeft: "0.4vw" }}
              src={mail}
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
