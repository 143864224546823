import React from 'react'

function ErrorPage() {
  return (
    <div>
        <p>My resume is not available at this time, </p>
    </div>
  )
}

export default ErrorPage